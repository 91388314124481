import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';

@Injectable({
    providedIn: 'root'
})
export class ParentService {

    constructor( private httpService: HttpService ) { }

    public getParentNewsletterOptStatus(parentId: string): Observable<any> {
        return this.httpService.get(BASE_URL + "/parent/" + parentId + "/newsletter/opt");
    }

    public toggleParentNewsletterOptStatus(parentId: string): Observable<any> {
        return this.httpService.put(BASE_URL + "/parent/" + parentId + "/newsletter/opt", {});
    }
}
