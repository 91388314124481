import { Injectable } from '@angular/core';
import './extentions/string-extension'


@Injectable({
    providedIn: 'root'
})
export class i18nService {
    private translations = {};
    
    constructor() {}
    
    setTranslations(messages) {
        this.translations = messages;
    }
    
    public getMessage(key, params?) {
        if (!key || key === '') {
            return '';
        }
        
        let value : String = this.translations[key];
        
        if ((!value || value === 'undefined')) {
            return key;
        }
        
        if (params && params.length > 0) {
            value = value.format(params);
        }
        return value;
    }
}
