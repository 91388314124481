import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor( private httpService: HttpService ) { }

    public putToken(): Observable<any> {
        return this.httpService.put(BASE_URL + '/token', {});
    }

    public getRedirectUrl(): Observable<any> {
        return this.httpService.get(BASE_URL + '/redirecturl');
    }
}
