import { Component, OnInit } from '@angular/core';
import {AlertMessageService} from './alert-message.service';
import {AlertMessage} from './alert-message';
import {LogLevel} from './log-level';

@Component({
  selector: 'jrz-common-alert-messages',
  templateUrl: './alert-messages.component.html',
})
export class AlertMessagesComponent implements OnInit {

  constructor(public alertMessageService: AlertMessageService) { }

  ngOnInit() {
  }

  alertCssClass(message: AlertMessage) {
    if (!message) {
      return 'alert-warning';
    } else if (message.type === LogLevel.INFO) {
      return 'alert-success';
    } else {
      return 'alert-' + message.type.code;
    }
  }
}
