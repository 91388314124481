import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomerDomainNameService {
     private readonly URL_PREFIX = '/customerdomainname';

    constructor( private httpService: HttpService ) { }

    public getCustomerDomainName(): Observable<string> {
        return this.httpService.get(BASE_URL + this.URL_PREFIX)
            .pipe(
                map((data: any) => {
                    if (data.result) {
                        return data.payload;
                    }
                })
            );
    }
}
