import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';
import {Login} from './login';
import {HttpService} from 'jrz-http';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor( private httpService: HttpService ) { }

    public login(login: Login): Observable<any> {
        return this.httpService.put(BASE_URL + '/login', login);
    }
}
