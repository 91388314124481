import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@angular/compiler';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: "https://db58182dc32c4519ae656a33f6b2fbd5@sentry.kidskonnect.nl/22" ,
  release: "auth-ui@" + environment.appVersion,
  environment: environment.production ? 'prod' : 'dev',
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
