import { Injectable } from '@angular/core';
import {HttpService} from "jrz-http";
import {Observable} from "rxjs";
import {BASE_URL} from "./base-url.const";

@Injectable({
  providedIn: "root"
})
export class GenericDataService {
  constructor( private httpService: HttpService ) { }

  public getCurrentYearFromServer(): Observable<any> {
    return this.httpService.get(BASE_URL + "/genericdata/currentyear");
  }
}