import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SsoService {
    private readonly URL_PREFIX = '/sso';
    private _supportsEmployeeSso: boolean = false;
    public isUsingExternalIDPForEmployees: boolean = false;

    constructor( private httpService: HttpService) { }

    public getSSOSettings(): Observable<any> {
        return this.httpService.get(BASE_URL + this.URL_PREFIX + '/settings')
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }

    public supportsEmployeeSso(): boolean {
        return this._supportsEmployeeSso;
    }

    public setSupportsEmployeeSso(value: boolean) {
        this._supportsEmployeeSso = value;
    }
}
