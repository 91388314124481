import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';
import {map} from 'rxjs/operators';
import {CaptchaAdapter} from './captcha';

@Injectable({
    providedIn: 'root'
})
export class CaptchaService {
    private readonly URL_PREFIX = '/captcha';
    private readonly URL_PREFIX_RESET = '/captcha/reset';
    
    constructor( private httpService: HttpService,
                 private captchaAdapter: CaptchaAdapter) { }
    
    public showCaptcha(): Observable<any> {
        return this.httpService.get(BASE_URL + this.URL_PREFIX)
            .pipe(
                map((data: any) => {
                    if (data.result && data.payload) {
                        return this.captchaAdapter.adapt(data.payload)
                    } else {
                        return null;
                    }
                })
            )
    }

    public showCaptchaForReset(): Observable<any> {
        return this.httpService.get(BASE_URL + this.URL_PREFIX_RESET)
            .pipe(
                map((data: any) => {
                    if (data.result && data.payload) {
                        return this.captchaAdapter.adapt(data.payload)
                    } else {
                        return null;
                    }
                })
            )
    }
}
