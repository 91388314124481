import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {appRoutesNames} from "../app/app.routes.name";
import {MainComponent} from "../main/main.component";
import {LoginComponent} from "../main/login/login.component";
import {ResetPasswordComponent} from "../main/reset-password/reset-password.component";
import {ChangePasswordComponent} from "../main/change-password/change-password.component";
import {NewPasswordComponent} from "../main/new-password/new-password.component";
import {VersionComponent} from "../main/version/version.component";
import {NewsletterOptInOutComponent} from "../main/newsletter-opt-in-out/newsletter-opt-in-out.component";
import {ErrorComponent} from "../error/error.component";

const routes: Routes = [
    {
        path: '',
        redirectTo: appRoutesNames.LOGIN,
        pathMatch: 'full'
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: '',
        component: MainComponent,
        children: [
            { path: appRoutesNames.LOGIN, component: LoginComponent },
            { path: appRoutesNames.RESET_PASSWORD, component: ResetPasswordComponent },
            { path: appRoutesNames.CHANGE_PASSWORD, component: ChangePasswordComponent },
            { path: appRoutesNames.NEW_PASSWORD, component: NewPasswordComponent },
            { path: appRoutesNames.VERSION, component: VersionComponent },
            { path: appRoutesNames.NEWSLETTER_OPT_IN_OUT, component: NewsletterOptInOutComponent},
            { path: "**", redirectTo: appRoutesNames.LOGIN}
        ]
    },
];
// configures NgModule imports and exports
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
