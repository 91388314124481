import {Injectable} from '@angular/core';
import {AdapterInterface} from 'jrz-http'

export class Captcha {
    constructor(
        public blockedUntil: string,
    ) {}
}

@Injectable({
    providedIn: 'root'
})

export class CaptchaAdapter implements AdapterInterface<Captcha> {
    adapt(item: any): Captcha {
        return new Captcha(
            item.blockedUntil,
        );
    }
}
