import {Injectable} from '@angular/core';

export class Resource {
    constructor(public filename: string,
                public type: string){}
}

@Injectable({
    providedIn: 'root'
})
export class ResourceAdapter {
    adapt(item: any): Resource {
        return new Resource(
            item.filename,
            item.type
        )
    }
}
