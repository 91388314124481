import {Injectable} from '@angular/core';
import {ResourceAdapter} from './resource';

export class Manifest {
    constructor(public version: string,
                public resources: []){}
}

@Injectable({
    providedIn: 'root'
})
export class ManifestAdapter {
    
    constructor(private resourceAdapter: ResourceAdapter) { }
    
    adapt(item: any): Manifest {
        return new Manifest(
            item.version,
            item.resources == null ? null: item.resources.map(item => this.resourceAdapter.adapt(item))
        )
    }
}
