import {LogLevel} from './log-level';

export class AlertMessage {

    constructor(public type: LogLevel, public text: string) {}

    private timer: any;

    public setTimer(timeout: number, onTimeout: () => void) {
        this.timer = setTimeout(onTimeout, timeout);
    }

    public cancelTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
}
