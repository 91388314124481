import { NgModule } from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {JrzCommonModule} from 'jrz-common';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, JrzCommonModule],
  exports: [],
  providers: []
})
export class JrzHttpModule { }
