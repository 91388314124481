interface EnumCustomerInformationBlockFacet {}
export class CustomerInformationBlockFacet implements EnumCustomerInformationBlockFacet {
    private static facets: { [code: string] : CustomerInformationBlockFacet } = {};
    
    static readonly PRE_LOGIN = new CustomerInformationBlockFacet("login_page_pre_text");
    static readonly POST_LOGIN = new CustomerInformationBlockFacet("login_page_post_text");
  
    
    private constructor(public readonly code: string) {
        CustomerInformationBlockFacet.facets[code] = this;
    }
    
    public static parseEnum(data: string): CustomerInformationBlockFacet {
        return CustomerInformationBlockFacet.facets[data];
    }
}
