<div id="login-page" class="container-fluid h-100">
  <div class="row h-100">
    <div id="login-image-col" class="col h-100">
    </div>
    <div id="login-component-col" class="col h-100 padding-0">
        <div class="inner-panel">
          <div class="logo-container"><div class="logo"></div></div>
          <div class="expired-download-link-title"><h3><b>{{i18nService.getMessage('error.expired.download_link.title')}}</b></h3></div>
          <div class="expired-download-link-info"><h5>{{i18nService.getMessage('error.expired.download_link.info')}}</h5></div>
          <div class="form-group">
            <button id="login-button" [routerLink]="loginPageLink" class="btn btn-primary padding-top-20">
              <span>{{i18nService.getMessage('login.action.login')}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
