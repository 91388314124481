import { NgModule } from '@angular/core';
import { JrzCommonComponent } from './jrz-common.component';
import {AlertMessagesComponent} from './alert-message/alert-messages.component';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from './loader/loader.component';

@NgModule({
  declarations: [
      JrzCommonComponent,
      AlertMessagesComponent,
      LoaderComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
      JrzCommonComponent,
      AlertMessagesComponent,
      LoaderComponent
  ]
})
export class JrzCommonModule { }
