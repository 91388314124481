import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';
import {map} from 'rxjs/operators';
import {CustomerInformationBlock, CustomerInformationBlockAdapter} from './customer-information-block';

@Injectable({
    providedIn: 'root'
})
export class CustomerInformationBlockService {
    private readonly URL_PREFIX = '/customerinformationblock';
    
    constructor( private httpService: HttpService,
                 private customerInformationBlockAdapter: CustomerInformationBlockAdapter) { }
    
    public getCustomerInformationBlocks(): Observable<CustomerInformationBlock[]> {
        return this.httpService.get(BASE_URL + this.URL_PREFIX)
            .pipe(
                map((data: any) => {
                    if (data.result) {
                        return data.payload.map(customerInformationBlock => this.customerInformationBlockAdapter.adapt(customerInformationBlock));
                    }
                })
            );
    }
}
