import {Component, OnInit} from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
    selector: 'auth-version',
    templateUrl: 'version.component.html'
})
export class VersionComponent implements OnInit {
    
    constructor(){}

    ngOnInit(): void {}

    getVersion(){
        return 'Auth UI version: ' + environment.appVersion;
    }
    
}