<ng-container>
    <div class="logo"></div>

    <ng-container *ngIf="isParent">
        <div class="form-group" *ngIf="!optOut && optOutInSuccess">
            <div>{{i18nService.getMessage('newsletter.opt_out.successful')}}</div>
        </div>
        <div class="form-group" *ngIf="optOut && optOutInSuccess">
            <div>{{i18nService.getMessage('newsletter.opt_in.successful')}}</div>
        </div>

        <div class="form-group" *ngIf="!optOut && !optOutInSuccess">
            {{i18nService.getMessage('newsletter.opt_out.text')}}
        </div>

        <div class="form-group" *ngIf="optOut && !optOutInSuccess">
            {{i18nService.getMessage('newsletter.opt_in.text')}}
        </div>

        <div class="form-group">
            {{i18nService.getMessage('newsletter.opt_in_out.username', username)}}
        </div>

        <div class="form-group" *ngIf="!optOutInSuccess">
            <button (click)="optInOut()" id="login-button" class="btn btn-primary">
                <span *ngIf="optOut">{{i18nService.getMessage('newsletter.action.opt_in')}}</span>
                <span *ngIf="!optOut">{{i18nService.getMessage('newsletter.action.opt_out')}}</span>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="!isParent">
        <div class="form-group">
            <div>{{i18nService.getMessage('newsletter.opt_in_out.not_a_parent')}}</div>
        </div>
    </ng-container>
</ng-container>