<form [formGroup]="changePasswordForm">
    <div class="page-title"><h1>{{i18nService.getMessage('changepassword.title')}}</h1></div>
    <loader *ngIf="isInitialLoading$ | async"></loader>
    <span *ngIf="compulsory" class="compulsory-change-password">{{i18nService.getMessage('changepassword.compulsory_text')}}</span>
    <div class="form-group">
        <label>{{i18nService.getMessage('changepassword.name')}}</label>
        <input class="form-control full-width" disabled="" [value]="userDetails.fullname">
    </div>
    <div class="form-group">
        <label>{{i18nService.getMessage('changepassword.username')}}</label>
        <input class="form-control full-width" disabled="" [value]="userDetails.username">
    </div>
        
    <div class="form-group">
        <div class="input-group">
            <label class="full-width" for="currentPassword">{{i18nService.getMessage('changepassword.current_password')}}</label>
            <input id="currentPassword" autocomplete="off" type="password" formControlName="currentPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.currentPassword.errors }"/>
            <div class="input-group-append">
                <button (click)="showHidePassword('currentPassword')" class="input-group-text">
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIj48cGF0aCBkPSJNMjg4IDI4OGE2NCA2NCAwIDAgMCAwLTEyOGMtMSAwLTEuODguMjQtMi44NS4yOWE0Ny41IDQ3LjUgMCAwIDEtNjAuODYgNjAuODZjMCAxLS4yOSAxLjg4LS4yOSAyLjg1YTY0IDY0IDAgMCAwIDY0IDY0em0yODQuNTItNDYuNkM1MTguMjkgMTM1LjU5IDQxMC45MyA2NCAyODggNjRTNTcuNjggMTM1LjY0IDMuNDggMjQxLjQxYTMyLjM1IDMyLjM1IDAgMCAwIDAgMjkuMTlDNTcuNzEgMzc2LjQxIDE2NS4wNyA0NDggMjg4IDQ0OHMyMzAuMzItNzEuNjQgMjg0LjUyLTE3Ny40MWEzMi4zNSAzMi4zNSAwIDAgMCAwLTI5LjE5ek0yODggOTZhMTI4IDEyOCAwIDEgMS0xMjggMTI4QTEyOC4xNCAxMjguMTQgMCAwIDEgMjg4IDk2em0wIDMyMGMtMTA3LjM2IDAtMjA1LjQ2LTYxLjMxLTI1Ni0xNjBhMjk0Ljc4IDI5NC43OCAwIDAgMSAxMjkuNzgtMTI5LjMzQzE0MC45MSAxNTMuNjkgMTI4IDE4Ny4xNyAxMjggMjI0YTE2MCAxNjAgMCAwIDAgMzIwIDBjMC0zNi44My0xMi45MS03MC4zMS0zMy43OC05Ny4zM0EyOTQuNzggMjk0Ljc4IDAgMCAxIDU0NCAyNTZjLTUwLjUzIDk4LjY5LTE0OC42NCAxNjAtMjU2IDE2MHoiLz48L3N2Zz4=" *ngIf="showCurrentPassword" alt="hide password" class="show-hide-password-button">
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48cGF0aCBkPSJNNjM3IDQ4NS4yNUwyMyAxLjc1QTggOCAwIDAgMCAxMS43NiAzbC0xMCAxMi41MUE4IDggMCAwIDAgMyAyNi43NWw2MTQgNDgzLjVhOCA4IDAgMCAwIDExLjI1LTEuMjVsMTAtMTIuNTFhOCA4IDAgMCAwLTEuMjUtMTEuMjR6TTMyMCA5NmExMjguMTQgMTI4LjE0IDAgMCAxIDEyOCAxMjhjMCAyMS42Mi01LjkgNDEuNjktMTUuNCA1OS41N2wyNS40NSAyMEM0NzEuNjUgMjgwLjA5IDQ4MCAyNTMuMTQgNDgwIDIyNGMwLTM2LjgzLTEyLjkxLTcwLjMxLTMzLjc4LTk3LjMzQTI5NC44OCAyOTQuODggMCAwIDEgNTc2LjA1IDI1NmEyOTkuNzMgMjk5LjczIDAgMCAxLTY3Ljc3IDg3LjE2bDI1LjMyIDE5Ljk0YzI4LjQ3LTI2LjI4IDUyLjg3LTU3LjI2IDcwLjkzLTkyLjUxYTMyLjM1IDMyLjM1IDAgMCAwIDAtMjkuMTlDNTUwLjMgMTM1LjU5IDQ0Mi45NCA2NCAzMjAgNjRhMzExLjIzIDMxMS4yMyAwIDAgMC0xMzAuMTIgMjguNDNsNDUuNzcgMzZDMjU4LjI0IDEwOC41MiAyODcuNTYgOTYgMzIwIDk2em02MC44NiAxNDYuODNBNjMuMTUgNjMuMTUgMCAwIDAgMzIwIDE2MGMtMSAwLTEuODkuMjQtMi44NS4yOWE0NS4xMSA0NS4xMSAwIDAgMS0uMjQgMzIuMTl6bS0yMTcuNjItNDkuMTZBMTU0LjI5IDE1NC4yOSAwIDAgMCAxNjAgMjI0YTE1OS4zOSAxNTkuMzkgMCAwIDAgMjI2LjI3IDE0NS4yOUwzNTYuNjkgMzQ2Yy0xMS43IDMuNTMtMjMuODUgNi0zNi42OCA2QTEyOC4xNSAxMjguMTUgMCAwIDEgMTkyIDIyNGMwLTIuNDQuNTktNC43Mi43Mi03LjEyek0zMjAgNDE2Yy0xMDcuMzYgMC0yMDUuNDctNjEuMzEtMjU2LTE2MCAxNy40My0zNCA0MS4wOS02Mi43MiA2OC4zMS04Ni43MmwtMjUuODYtMjAuMzdjLTI4LjQ4IDI2LjI4LTUyLjg3IDU3LjI1LTcwLjkzIDkyLjVhMzIuMzUgMzIuMzUgMCAwIDAgMCAyOS4xOUM4OS43MSAzNzYuNDEgMTk3LjA3IDQ0OCAzMjAgNDQ4YTMxMS4yNSAzMTEuMjUgMCAwIDAgMTMwLjEyLTI4LjQzbC0yOS4yNS0yM0MzODkuMDYgNDA4Ljg0IDM1NS4xNSA0MTYgMzIwIDQxNnoiLz48L3N2Zz4=" *ngIf="!showCurrentPassword" alt="show password" class="show-hide-password-button">
            </button>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="input-group">
            <label class="full-width" for="newPassword">{{i18nService.getMessage('changepassword.new_password')}}</label>
            <input id="newPassword" autocomplete="off" type="password" formControlName="newPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.newPassword.errors }"/>
            <div class="input-group-append">
            <button (click)="showHidePassword('newPassword')" class="input-group-text">
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIj48cGF0aCBkPSJNMjg4IDI4OGE2NCA2NCAwIDAgMCAwLTEyOGMtMSAwLTEuODguMjQtMi44NS4yOWE0Ny41IDQ3LjUgMCAwIDEtNjAuODYgNjAuODZjMCAxLS4yOSAxLjg4LS4yOSAyLjg1YTY0IDY0IDAgMCAwIDY0IDY0em0yODQuNTItNDYuNkM1MTguMjkgMTM1LjU5IDQxMC45MyA2NCAyODggNjRTNTcuNjggMTM1LjY0IDMuNDggMjQxLjQxYTMyLjM1IDMyLjM1IDAgMCAwIDAgMjkuMTlDNTcuNzEgMzc2LjQxIDE2NS4wNyA0NDggMjg4IDQ0OHMyMzAuMzItNzEuNjQgMjg0LjUyLTE3Ny40MWEzMi4zNSAzMi4zNSAwIDAgMCAwLTI5LjE5ek0yODggOTZhMTI4IDEyOCAwIDEgMS0xMjggMTI4QTEyOC4xNCAxMjguMTQgMCAwIDEgMjg4IDk2em0wIDMyMGMtMTA3LjM2IDAtMjA1LjQ2LTYxLjMxLTI1Ni0xNjBhMjk0Ljc4IDI5NC43OCAwIDAgMSAxMjkuNzgtMTI5LjMzQzE0MC45MSAxNTMuNjkgMTI4IDE4Ny4xNyAxMjggMjI0YTE2MCAxNjAgMCAwIDAgMzIwIDBjMC0zNi44My0xMi45MS03MC4zMS0zMy43OC05Ny4zM0EyOTQuNzggMjk0Ljc4IDAgMCAxIDU0NCAyNTZjLTUwLjUzIDk4LjY5LTE0OC42NCAxNjAtMjU2IDE2MHoiLz48L3N2Zz4=" *ngIf="showNewPassword" alt="hide password" class="show-hide-password-button">
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDAgNTEyIj48cGF0aCBkPSJNNjM3IDQ4NS4yNUwyMyAxLjc1QTggOCAwIDAgMCAxMS43NiAzbC0xMCAxMi41MUE4IDggMCAwIDAgMyAyNi43NWw2MTQgNDgzLjVhOCA4IDAgMCAwIDExLjI1LTEuMjVsMTAtMTIuNTFhOCA4IDAgMCAwLTEuMjUtMTEuMjR6TTMyMCA5NmExMjguMTQgMTI4LjE0IDAgMCAxIDEyOCAxMjhjMCAyMS42Mi01LjkgNDEuNjktMTUuNCA1OS41N2wyNS40NSAyMEM0NzEuNjUgMjgwLjA5IDQ4MCAyNTMuMTQgNDgwIDIyNGMwLTM2LjgzLTEyLjkxLTcwLjMxLTMzLjc4LTk3LjMzQTI5NC44OCAyOTQuODggMCAwIDEgNTc2LjA1IDI1NmEyOTkuNzMgMjk5LjczIDAgMCAxLTY3Ljc3IDg3LjE2bDI1LjMyIDE5Ljk0YzI4LjQ3LTI2LjI4IDUyLjg3LTU3LjI2IDcwLjkzLTkyLjUxYTMyLjM1IDMyLjM1IDAgMCAwIDAtMjkuMTlDNTUwLjMgMTM1LjU5IDQ0Mi45NCA2NCAzMjAgNjRhMzExLjIzIDMxMS4yMyAwIDAgMC0xMzAuMTIgMjguNDNsNDUuNzcgMzZDMjU4LjI0IDEwOC41MiAyODcuNTYgOTYgMzIwIDk2em02MC44NiAxNDYuODNBNjMuMTUgNjMuMTUgMCAwIDAgMzIwIDE2MGMtMSAwLTEuODkuMjQtMi44NS4yOWE0NS4xMSA0NS4xMSAwIDAgMS0uMjQgMzIuMTl6bS0yMTcuNjItNDkuMTZBMTU0LjI5IDE1NC4yOSAwIDAgMCAxNjAgMjI0YTE1OS4zOSAxNTkuMzkgMCAwIDAgMjI2LjI3IDE0NS4yOUwzNTYuNjkgMzQ2Yy0xMS43IDMuNTMtMjMuODUgNi0zNi42OCA2QTEyOC4xNSAxMjguMTUgMCAwIDEgMTkyIDIyNGMwLTIuNDQuNTktNC43Mi43Mi03LjEyek0zMjAgNDE2Yy0xMDcuMzYgMC0yMDUuNDctNjEuMzEtMjU2LTE2MCAxNy40My0zNCA0MS4wOS02Mi43MiA2OC4zMS04Ni43MmwtMjUuODYtMjAuMzdjLTI4LjQ4IDI2LjI4LTUyLjg3IDU3LjI1LTcwLjkzIDkyLjVhMzIuMzUgMzIuMzUgMCAwIDAgMCAyOS4xOUM4OS43MSAzNzYuNDEgMTk3LjA3IDQ0OCAzMjAgNDQ4YTMxMS4yNSAzMTEuMjUgMCAwIDAgMTMwLjEyLTI4LjQzbC0yOS4yNS0yM0MzODkuMDYgNDA4Ljg0IDM1NS4xNSA0MTYgMzIwIDQxNnoiLz48L3N2Zz4=" *ngIf="!showNewPassword" alt="show password" class="show-hide-password-button">
            </button>
            </div>
        </div>
    </div>
    <span>{{i18nService.getMessage('changepassword.password_requirement.text')}}</span>
    <ul class="password-criteria">
        <li [ngClass]="{ 'valid': passRequirementMet['passwordMinCharacters'].valid}">{{i18nService.getMessage('changepassword.password_requirement.10chars')}}</li>
        <li [ngClass]="{ 'valid': passRequirementMet['passwordMinLowerCase'].valid}">{{i18nService.getMessage('changepassword.password_requirement.lowercase')}}</li>
        <li [ngClass]="{ 'valid': passRequirementMet['passwordMinUpperCase'].valid}">{{i18nService.getMessage('changepassword.password_requirement.uppercase')}}</li>
        <li [ngClass]="{ 'valid': passRequirementMet['passwordMinNumber'].valid}">{{i18nService.getMessage('changepassword.password_requirement.number')}}</li>
    </ul>
    <div *ngIf="submitted && form.currentPassword.errors" class="invalid-feedback">
        <div *ngIf="form.currentPassword.errors.required">{{i18nService.getMessage('login.reset_password.modal_panel.currentPassword.required')}}</div>
    </div>
    <div *ngIf="submitted && form.newPassword.errors" class="invalid-feedback">
        <div *ngIf="form.newPassword.errors.required">{{i18nService.getMessage('login.reset_password.modal_panel.newPassword.required')}}</div>
    </div>
    <div class="form-group errors">
        <div class="text-danger" *ngFor="let error of errors">{{error.messageCode}}</div>
    </div>
    <div class="form-group">
        <button type="submit" (click)="onSubmit()" class="btn btn-primary change-password-button" [disabled]="isRequestingChangePassword || !changePasswordForm.valid || passwordChanged">
            <span *ngIf="isRequestingChangePassword" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!isRequestingChangePassword">{{i18nService.getMessage('changepassword.button_text')}}</span>
        </button>
    </div>
</form>