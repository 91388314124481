import { i18nService } from 'jrz-common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {finalize, first} from 'rxjs/operators';
import {CredentialsService, ChangePasswordRequest, UserService, TokenService} from 'jrz-auth-api';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpError} from 'jrz-http';

@Component({
    selector: 'auth-change-password',
    templateUrl: 'change-password.component.html',
    styleUrls: ['change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    public isInitialLoading$: Observable<boolean>;
    
    changePasswordForm: FormGroup;
    showCurrentPassword: boolean = false;
    showNewPassword: boolean = false;
    passwordChanged: boolean = false;
    submitted: boolean = false;
    compulsory: boolean = false;
    errors = [];
    
    public isRequestingChangePassword: boolean = false;

    userDetails = {
        fullname: "",
        username: ""
    }

    passRequirementMet = {
        "passwordMinLowerCase": { "valid": false },
        "passwordMinNumber": { "valid": false },
        "passwordMinUpperCase": { "valid": false },
        "passwordMinCharacters": { "valid": false }
    };

    passRequirement = {
        passwordMinLowerCase: 1,
        passwordMinNumber: 1,
        passwordMinUpperCase: 1,
        passwordMinCharacters: 10
      };

      passRequirementPasswordMinLowerCase = "(?=([^a-z]*[a-z])\{" + this.passRequirement.passwordMinLowerCase + ",\})";
      passRequirementPasswordMinUpperCase = "(?=([^A-Z]*[A-Z])\{" + this.passRequirement.passwordMinUpperCase + ",\})";
      passRequirementPasswordMinNumber = "(?=([^0-9]*[0-9])\{" + this.passRequirement.passwordMinNumber + ",\})";
      passRequirementPasswordMinCharacters = "^.{" + this.passRequirement.passwordMinCharacters + ",}$";

      passRequirementPasswordMinLowerCaseRegex = new RegExp(this.passRequirementPasswordMinLowerCase);
      passRequirementPasswordMinUpperCaseRegex = new RegExp(this.passRequirementPasswordMinUpperCase);
      passRequirementPasswordMinNumberRegex = new RegExp(this.passRequirementPasswordMinNumber);
      passRequirementPasswordMinCharactersRegex = new RegExp(this.passRequirementPasswordMinCharacters);

      newPassworPattern = [
        this.passRequirementPasswordMinLowerCase,
        this.passRequirementPasswordMinUpperCase,
        this.passRequirementPasswordMinNumber,
        this.passRequirementPasswordMinCharacters
      ]
        .map(item => item.toString())
        .join("");
    
    constructor(
        private formBuilder: FormBuilder,
        private credentialsService: CredentialsService,
        private route: ActivatedRoute,
        public userService: UserService,
        public i18nService: i18nService,
        private tokenService: TokenService
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.compulsory) {
                this.compulsory = true;
            }

            this.changePasswordForm = this.formBuilder.group({
                currentPassword: ['', Validators.required],
                newPassword: ['', [Validators.required, Validators.pattern(this.newPassworPattern)]],
                confirmPassword: ['']
              });
        });

        this.userService.getUserDetails().subscribe(data => {
            this.userDetails = data.payload;
        });

        this.changePasswordForm.controls['newPassword'].valueChanges.subscribe(value => {
            this.checkPassWordForFeedback(value);
        });

    }

    checkPassWordForFeedback(newPasswordTyping) {
        //loop through it after adding regex to it
        this.passRequirementMet["passwordMinLowerCase"].valid = this.passRequirementPasswordMinLowerCaseRegex.test(newPasswordTyping);
        this.passRequirementMet["passwordMinUpperCase"].valid = this.passRequirementPasswordMinUpperCaseRegex.test(newPasswordTyping);
        this.passRequirementMet["passwordMinNumber"].valid = this.passRequirementPasswordMinNumberRegex.test(newPasswordTyping);
        this.passRequirementMet["passwordMinCharacters"].valid = this.passRequirementPasswordMinCharactersRegex.test(newPasswordTyping);
    }

    showHidePassword(id) {
        let passwordElement = (document.getElementById(id) as HTMLInputElement);

        if (id == 'currentPassword') {
            this.showCurrentPassword = !this.showCurrentPassword;
        } else {
            this.showNewPassword = !this.showNewPassword;
        }

        if (passwordElement.type == "password") {
            passwordElement.type = "text"
        } else {
            passwordElement.type = "password";
        }
    }

    get form() {
        return this.changePasswordForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.errors = [];
        
        if (this.changePasswordForm.invalid) {
            return;
        }

        this.isRequestingChangePassword = true;

        const changePasswordRequest: ChangePasswordRequest = Object.assign({}, this.changePasswordForm.value);
        changePasswordRequest.confirmPassword = changePasswordRequest.newPassword; //https://uxmovement.medium.com/why-the-confirm-password-field-must-die-aacb9f9c9bce

        this.credentialsService.changePassword(changePasswordRequest).pipe(first(), finalize(() => this.isRequestingChangePassword = false)).subscribe(data => {
            this.errors = data.messages;

            if (data.result) {
                window.sessionStorage.setItem("authToken", data.payload);
                
                this.passwordChanged = true;

                this.tokenService.getRedirectUrl().subscribe(redirect => {
                    setTimeout(() => (location.href = redirect.url), 2000);
                });
            }
            }, errorResponse => {
                if (errorResponse instanceof HttpError) {
                    this.errors = errorResponse.messages;
                }
            });
    }
}