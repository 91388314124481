import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(
        private httpClient: HttpClient
    ) { }

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
    };
      
    get(url: string): Observable<Object> {
        return this.httpClient.get(url);
    }

    post(url: string, body: any): Observable<Object> {
        return this.httpClient.post(url, body);
    }

    put(url: string, body: any): Observable<Object> {
        return this.httpClient.put(url, body);
    }

    delete(url: string): Observable<Object> {
        return this.httpClient.delete(url);
    }
}
