import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {i18nService} from 'jrz-common';
import { ParentService } from 'projects/jrz-auth-api/src/lib/parent/parent.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'newsletter-opt-in-out',
    templateUrl: 'newsletter-opt-in-out.component.html',
    styleUrls: ['newsletter-opt-in-out.component.scss']
})
export class NewsletterOptInOutComponent implements OnInit {
    newsletterOptOutInForm: FormGroup;
    isParent = true;
    optOut = true;
    optOutInSuccess = false;
    username: string = "";
    parentId: string;
    
    constructor(
        public i18nService: i18nService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private parentService: ParentService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            //set language
            // params.lang 

            //create form
            this.parentId = params.pid;

            if(params.pid){
                //get username, opt status from parent
                this.parentService.getParentNewsletterOptStatus(params.pid).pipe(
                    first())
                .subscribe(result => {
                    this.isParent = true;
                    this.username = result.payload.username;
                    this.optOut = result.payload.optOut;
                }, err => {
                    this.isParent = false;
                });
            }else{
                this.isParent = false;
            }
        });
    }


    optInOut() {
        this.parentService.toggleParentNewsletterOptStatus(this.parentId).pipe(
            first())
            .subscribe(result => {
                this.optOutInSuccess = true;
            }, err => {
                this.optOutInSuccess = false;
            });
    }

    close() {
        window.self.close();
    }
}
