import {Injectable} from '@angular/core';
import {AdapterInterface} from 'jrz-http'
import {CustomerInformationBlockFacet} from './customer-information-block-facet';

export class CustomerInformationBlock {
    constructor(
        public facet: CustomerInformationBlockFacet,
        public content: string
    ) {}
}

@Injectable({
    providedIn: 'root'
})

export class CustomerInformationBlockAdapter implements AdapterInterface<CustomerInformationBlock> {
    adapt(item: any): CustomerInformationBlock {
        return new CustomerInformationBlock(
            CustomerInformationBlockFacet.parseEnum(item.facetCode),
            item.content
        );
    }
}
