import { ChangePasswordComponent } from './change-password/change-password.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { JrzAuthApiModule} from 'jrz-auth-api';
import { JrzCommonModule} from 'jrz-common';
import { JrzHttpModule} from 'jrz-http';
import {ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {MainComponent} from './main.component';
import {RouterModule} from '@angular/router';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import { VersionComponent } from './version/version.component';
import {NewPasswordComponent} from "./new-password/new-password.component";
import { NewsletterOptInOutComponent } from './newsletter-opt-in-out/newsletter-opt-in-out.component';

@NgModule({
    declarations: [
        MainComponent,
        LoginComponent,
        NewPasswordComponent,
        ResetPasswordComponent,
        ChangePasswordComponent,
        NewsletterOptInOutComponent,
        VersionComponent
    ],
    imports: [
        BrowserModule,
        JrzAuthApiModule,
        JrzCommonModule,
        JrzHttpModule,
        ReactiveFormsModule,
        RouterModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {siteKey: '6LeVaiEUAAAAAJsClLNfDFlF07C8XxX8hTx1ZRs7'} as RecaptchaSettings
        }
    ],
    exports: [
        LoginComponent,
        ResetPasswordComponent,
        ChangePasswordComponent,
        NewsletterOptInOutComponent,
        NewPasswordComponent,
        VersionComponent,
        MainComponent
    ]
})
export class MainModule { }
