<form [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()">

    <div class="logo-container"><div class="logo"></div></div>

    <span class="new-password-title"><h3>{{i18nService.getMessage('reset.change.password.title')}}</h3></span>

    <span *ngIf="success">{{i18nService.getMessage('changepassword.new.password.stored.successfully')}}</span>

    <span class="text-danger" *ngIf="!isTokenValid">{{i18nService.getMessage('reset.change.password.invalid')}}</span>

    <div *ngIf="isTokenValid && !success">
        <span class="new-password-intro">{{i18nService.getMessage('reset.change.password.info')}} {{i18nService.getMessage('reset.change.password.requirements')}}</span>

        <div class="form-new-password-container">
            <div class="form-group">
                <label for="password_new">{{i18nService.getMessage('reset.change.password.new')}}</label>
                <input id="new:password_new" type="password" formControlName="passwordNew" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.passwordNew.errors }"/>
                <div *ngIf="submitted && form.passwordNew.errors" class="invalid-feedback">
                    <div *ngIf="form.passwordNew.errors.required">{{i18nService.getMessage('reset.change.password.new.required')}}</div>
                </div>
            </div>

            <div class="form-group">
                <label for="password_new_repeat">{{i18nService.getMessage('reset.change.password.repeat')}}</label>
                <input id="new:password_new_repeat" type="password" formControlName="passwordNewRepeat" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.passwordNewRepeat.errors }"/>
                <div *ngIf="submitted && form.passwordNewRepeat.errors" class="invalid-feedback">
                    <div *ngIf="form.passwordNewRepeat.errors.required">{{i18nService.getMessage('reset.change.password.repeat.required')}}</div>
                </div>
            </div>

            <div class="form-group errors" *ngIf="!processing">
                <div class="text-danger" *ngFor="let error of errors">{{i18nService.getMessage(error.messageCode)}}</div>
            </div>

            <div class="form-group">
                <button id="login-button" [disabled]="processing || initialLoading" class="btn btn-primary">
                    <span *ngIf="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span *ngIf="!processing">{{i18nService.getMessage('reset.change.password.button.reset')}}</span>
                </button>
            </div>

        </div>
    </div>
    <div class="back-to-login"><a *ngIf="!isTokenValid || success" [routerLink]="backToLoginLink" >{{i18nService.getMessage('login.action.back_to_login')}}</a></div>
</form>
