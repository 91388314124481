export class LogLevel {
    private static logLevels: { [code: string]: LogLevel} = {};

    static readonly INFO = new LogLevel('info');
    static readonly WARNING = new LogLevel('warning');
    static readonly ERROR = new LogLevel('danger');

    private constructor(public code: string) {
        LogLevel.logLevels[code] = this;
    }

}
