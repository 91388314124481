import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class UserService {
     private readonly URL_PREFIX = '/user';

    constructor(private httpClient: HttpClient ) { }

    public getUserDetails(): Observable<any> {
        return this.httpClient.get(BASE_URL + this.URL_PREFIX, this.getOptionsWithToken());
    }

    getOptionsWithToken() {
        let token = window.sessionStorage.getItem("authToken");

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token });
        return { headers: headers };
    }
}
