import {ErrorMessage, ErrorMessageAdapter} from './error-message';
import {Injectable} from '@angular/core';
import {AdapterInterface} from '../adapter.interface';

export class HttpError {
    constructor(
        public messages: ErrorMessage[]
    ) {}
}

@Injectable({
    providedIn: 'root'
})

export class HttpErrorAdapter implements AdapterInterface<HttpError> {
    constructor(private errorMessageAdapter: ErrorMessageAdapter) {}
    
    adapt(item: any): HttpError {
        if (item == null) {
            return new HttpError(null);
        }
        
        return new HttpError(
            item.messages == null ? null : item.messages.map(message => this.errorMessageAdapter.adapt(message))
        );
    }
}
