import {Injectable} from '@angular/core';
import {AlertMessage} from './alert-message';
import {LogLevel} from './log-level';

@Injectable({
    providedIn: 'root'
})

export class AlertMessageService {
    constructor() {}

    public messages: AlertMessage[] = new Array<AlertMessage>();
    private snooze = false;
    public configuration: any = {
        alertTimeout: 5000,
        alertWarningTimeout: 8000,
        alertErrorTimeout: 8000
    };

    public showSuccessMessage(text: string) {
        this.addMessage(new AlertMessage(LogLevel.INFO, text));
    }

    public showWarnMessage(text: string) {
        this.addMessage(new AlertMessage(LogLevel.WARNING, text));
    }

    public showErrorMessage(text: string) {
        this.addMessage(new AlertMessage(LogLevel.ERROR, text));
    }

    public remove(message: AlertMessage) {
        for (let i = this.messages.length - 1; i >= 0; i--) {
            if (this.messages[i] === message) {
                this.messages[i].cancelTimer();
                this.messages.splice(i, 1);
                break;
            }
        }
    }

    private addMessage(message: AlertMessage) {
        if (this.snooze) {
            return;
        }

        this.removeMessageWithSameText(message);

        message.setTimer(this.getTimeout(message.type), () => {
            this.removeMessageWithSameText(message);
        });
        this.messages.push(message);
    }

    private removeMessageWithSameText(message: AlertMessage) {
        if (this.messages.length > 0) {
            for (let i = this.messages.length - 1; i >= 0; i--) {
                if (this.messages[i].text === message.text) {
                    if (this.messages[i] !== message) {
                        this.messages[i].cancelTimer();
                    }
                    this.messages.splice(i, 1);
                }
            }
        }
    }

    private getTimeout(alertMessageType: LogLevel): number {
        switch (alertMessageType) {
            case LogLevel.INFO:
                return this.configuration.alertTimeout;
            case LogLevel.WARNING:
                return this.configuration.alertWarningTimeout;
            case LogLevel.ERROR:
                return this.configuration.alertErrorTimeout;
            default:
                return this.configuration.alertTimeout;
        }
    }
}
