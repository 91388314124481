<div id="login-page" class="container-fluid h-100">
    <div class="row h-100">
        <div id="login-image-col" class="col h-100">
        </div>
        <div id="login-component-col" class="col h-100 padding-0">
            <div id="login-upper-styling-line"></div>
            <div *ngIf="rightBrowserButVersionNotSupported" class="alert alert-warning" role="alert">
                {{i18nService.getMessage('login.alert_waning.browser_outdated')}}
            </div>
            <jrz-common-alert-messages id="alert-messages"></jrz-common-alert-messages>
            <div>
                <div class="inner-panel">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <footer class="padding-top padding-bottom">
                <div class="footer-copyright">
                    <span *ngIf=currentYear>{{i18nService.getMessage('login.footer.copyright', currentYear)}}</span>
                </div>
            </footer>
        </div>
    </div>
</div>
