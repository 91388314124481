import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';
import {DOCUMENT} from '@angular/common';
import {Manifest, ManifestAdapter} from './manifest';
import {Resource, ResourceAdapter} from './resource';
import {HttpService} from 'jrz-http';

@Injectable({
    providedIn: 'root'
})
export class ThemingService {
    
    private _themeName: string;
    public logoFileName: string;
    public manifest: Manifest;
    
    constructor(@Inject(DOCUMENT) private document: Document,
                private httpService: HttpService,
                private manifestAdapter: ManifestAdapter ) {}
    
    public loadStyle(themeName: string) {
        let themeUrl = themeName ? "/themes/" + themeName + "/main.css" : "assets/css/main.css";
    
        var request = new XMLHttpRequest();
        request.open('GET', themeUrl, false);
        request.send();

        if(request.status === 200) {
            this.loadManifest(this.getThemeName());
            this.addStyleToDocument(themeUrl)
        } else {
            themeUrl = "assets/css/main.css";
            this.addStyleToDocument(themeUrl);
            this.addFaviconToHead("assets/favicon.ico")
        }
    }
    
    public addStyleToDocument(themeUrl: string): void {
        const head = this.document.getElementsByTagName('head')[0];
    
        let themeLink = this.document.getElementById(
            'client-theme'
        ) as HTMLLinkElement;
    
        if (themeLink) {
            themeLink.href =  themeUrl;
        } else {
            const style = this.document.createElement('link');
            style.id = 'client-theme';
            style.rel = 'stylesheet';
            style.href = `${themeUrl}`;
        
            head.appendChild(style);
        }
    }
    
    public loadManifest(themeName: string) {
        if(themeName) {
            return this.httpService.get('/themes/' + themeName + '/manifest.json').subscribe(
                data => {
                    this.manifest = this.manifestAdapter.adapt(data);
                    this.loadCustomFont(themeName);
                    this.loadBackgroundAndLogo(themeName);
                    this.loadFavicon(themeName);
                }
            )
        } else {
            this.addFaviconToHead("assets/favicon.ico");
        }
    }
    
    public loadCustomFont(themeName: string) {
        let customFonts: Resource[] = this.manifest.resources.filter((resource: Resource) => resource.type.indexOf('font') != -1);
        
        customFonts.forEach((customFont: Resource) => {
            let style: HTMLStyleElement = this.document.createElement('style');
            style.setAttribute('class', 'customFont');
            let fontFamily = customFont.type.split('@');
            let fontStyle = "@font-face {font-family: '" + fontFamily[1] + "'; src: url('" + '/themes/' + themeName + '/' + customFont.filename + "') }";
    
            style.innerText = fontStyle;
            this.document.head.appendChild(style);
        });
    }
    
    public loadBackgroundAndLogo(themeName: string): void {
        let logo: Resource = this.manifest.resources.find((resource: Resource) => resource.type === 'logo');
        let background: Resource = this.manifest.resources.find((resource: Resource) => resource.type === 'login_bg');
        
        if(logo) {
            document.documentElement.style.setProperty('--login-logo-url', 'url(/themes/' + themeName + '/' + logo.filename +')');
        }
        
        if (background) {
            document.documentElement.style.setProperty('--login-background-url', 'url(/themes/' + themeName + '/' + background.filename +')');
        }
    }
    
    public loadFavicon(themeName: string) {
        let favicon: Resource = this.manifest.resources.find((resource: Resource) => resource.type.indexOf('icon') != -1);
        
        if (favicon) {
            let faviconUrl = themeName ? "/themes/" + themeName + "/" + favicon.filename : "assets/favicon.ico";
            this.addFaviconToHead(faviconUrl);
        }
    
    }
    
    private addFaviconToHead(faviconUrl: string) {
        var link = document.createElement('link');
        var oldLink = document.getElementById('dynamic-favicon');
        link.id = 'dynamic-favicon';
        link.rel = 'shortcut icon';
        link.href = faviconUrl;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    }
    
    public getTheme(): Observable<any> {
        return this.httpService.get(BASE_URL + "/theme");
    }
    
    public getThemeName(): string {
        return this._themeName;
    }
    
    public setThemeName(value: string) {
        this._themeName = value;
    }
}
