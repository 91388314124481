import {
  ChildMinderService,
  CaptchaService,
  Captcha,
  Login,
  LoginService,
  ThemingService,
  CustomerInformationBlockService,
  CustomerInformationBlock,
  CustomerInformationBlockFacet,
  SsoService,
  CredentialsService
} from 'jrz-auth-api';

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {catchError, distinctUntilChanged, finalize, first, last, mapTo, share, startWith, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {appRoutesNames} from '../../app/app.routes.name';
import {i18nService} from 'jrz-common';
import {forkJoin, merge, Observable, of, timer} from 'rxjs';
import {ErrorMessage} from 'jrz-http';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from "@sentry/angular";


@Component({
    selector: 'auth-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
    public isInitialLoading$: Observable<boolean>;

    public readonly resetPasswordLink = '../' + appRoutesNames.RESET_PASSWORD;
    private readonly startLoadingTime = 1000;

    public loadedTheme : string = "";
    public preLoginText: string = null;
    public postLoginText: string = null;

    loginForm: FormGroup;
    initialLoading = false;
    submitted = false;
    errors: ErrorMessage[] = [];
    passwordResetSuccessful = false;

    public isLoggingIn: boolean = false;

    public showSsoPanel = false;
    public showEmployeeButtons= false;
    public ssoForEmployee: boolean = false;
    public isUsingExternalIDPForEmployees: boolean = false;

    readonly initSsoUrl = '/employee/#/initsso';

    constructor(
        private formBuilder: FormBuilder,
        private loginService: LoginService,
        public i18nService: i18nService,
        private childMinderService: ChildMinderService,
        private captchaService: CaptchaService,
        public credentialsService: CredentialsService,
        private route: ActivatedRoute,
        private themingService: ThemingService,
        private customerInformationBlockService: CustomerInformationBlockService,
        private ssoService: SsoService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.initialLoading = true;
        this.route.queryParams.subscribe(params => {
            this.passwordResetSuccessful = Boolean(params.resetPasswordSuccess);
            this.loginForm = this.formBuilder.group({
                username: [params.username, Validators.required],
                password: ['', Validators.required]
            });
        });

        this.loadedTheme = this.themingService.getThemeName();
        this.addOptionalFields()

        this.ssoForEmployee = this.ssoService.supportsEmployeeSso();
        this.isUsingExternalIDPForEmployees =  this.ssoService.isUsingExternalIDPForEmployees;
        this.showSsoPanel = this.ssoForEmployee || this.isUsingExternalIDPForEmployees;
    }

    private addOptionalFields() {
        const result$ = forkJoin([
            this.childMinderService.hasChildMinderModule().pipe(catchError( err => of(err))),
            this.captchaService.showCaptcha().pipe(catchError(err => of(err))),
            this.customerInformationBlockService.getCustomerInformationBlocks().pipe(catchError(err => of(err)))])
            .pipe(share())

        this.isInitialLoading$ = merge (
            // ON in 1s
            timer(this.startLoadingTime).pipe(takeUntil(result$), mapTo(true)),

            // OFF once we receive a result
            result$.pipe(mapTo(false))
        ).pipe(
            startWith(false),
            distinctUntilChanged(),
            share()
        )

        // only when result and isInitialLoading emitted, subscribe.
        forkJoin([result$, this.isInitialLoading$.pipe(last())])
            .pipe(
                first(),
                finalize(() => this.initialLoading = false))
            .subscribe(([[hasChildMinderModule, captcha, customerInformationBlocks]]: [[boolean, Captcha, CustomerInformationBlock[]], boolean]) => {;
                if(hasChildMinderModule) {
                    this.loginForm.addControl('childMinder', new FormControl(false));
                }

                if (captcha && captcha.blockedUntil) {
                    this.addCaptcha(captcha.blockedUntil)
                }

                if (customerInformationBlocks && customerInformationBlocks.length > 0) {
                    this.addCustomerInformationBlocks(customerInformationBlocks)
                }
            })
    }

    private addCaptcha(blockedUntil: string) {
        this.loginForm.addControl('captchaCode', new FormControl());
        this.errors = [{
            messageCode: 'login.result.blocked',
            parameters: [blockedUntil]
        }];
    }

    private addCustomerInformationBlocks(customerInformationBlocks: CustomerInformationBlock[]) {
        customerInformationBlocks.forEach((customerInformationBlock: CustomerInformationBlock) => {
            switch(customerInformationBlock.facet) {
                case CustomerInformationBlockFacet.PRE_LOGIN:
                    this.preLoginText = customerInformationBlock.content;
                    break;
                case CustomerInformationBlockFacet.POST_LOGIN:
                    this.postLoginText = customerInformationBlock.content;
                    break;
            }
        })
    }

    get form() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.passwordResetSuccessful = false;

        //make sure to always remove the overrideSso flag on logging in
        localStorage.removeItem("overrideSso");

        if (this.loginForm.invalid) {
            return;
        }

        this.isLoggingIn = true;
        const login: Login = Object.assign({}, this.loginForm.value);
        this.loginService.login(login)
            .pipe(
                first())
            .subscribe(result => {
                const baseUrl = window.location.protocol + "//" + result.domainServerName;

                if (result.mustResetPassword) {
                    window.sessionStorage.setItem("authToken", result.authToken);
                    this.router.navigate(["/change-password"], { queryParams: {compulsory: true} });
                } else {
                    if(result.redirectUrl.indexOf('/parent') !== -1) {
                      if (window.location.hostname === result.domainServerName) {
                        window.sessionStorage.setItem("authToken", result.authToken); //setting this for another domain isn't possible
                        window.location.href = baseUrl + '/parent';
                      } else {
                        window.location.href = baseUrl + '/parent?ut=' + result.authToken;
                      }
                    } else {
                        window.location.href = baseUrl + result.redirectUrl;
                    }
                }
            }, errorResponse => {
                if (errorResponse instanceof HttpErrorResponse) {
                    this.submitted = false;
                    this.form.password.setValue(null);

                    const captchaError = errorResponse.error?.messages?.find((error: any) => error.messageCode === 'login.result.blocked');
                    if (captchaError) {
                        this.addCaptcha(captchaError.parameters[0])
                    } else {
                        this.loginForm.removeControl('captchaCode');
                        this.errors = errorResponse.error?.messages;

                        if(this.errors == undefined || this.errors.length == 0){
                            this.errors = [{
                                messageCode: "login.result.failed.unknown",
                                parameters: []
                            }];

                            //send error response to sentry as json
                            Sentry.captureMessage("Login failed with an unknown reason, ErrorResponseMessage: " + JSON.stringify(errorResponse));
                        }
                    }
                }
                this.isLoggingIn = false;
            });
    }



    hideSSOPanel() {
        this.showSsoPanel = false;
    }

    showSSOPanel() {
        this.showSsoPanel = true;
    }

    handleSSOEmployeesButtonClick(){
        if (this.isUsingExternalIDPForEmployees){
          this.redirectToSSO();
          return;
        }

        this.showEmployeeButtons = !this.showEmployeeButtons;
    }

  redirectToSSO() {
    window.location.href = this.initSsoUrl;
  }

  ssoBack() {
    this.showEmployeeButtons = false
  }
}
