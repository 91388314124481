import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';

@Injectable({
    providedIn: 'root'
})
export class i18nMessageService {
    private readonly URL_PREFIX = '/i18n';
    
    constructor( private httpService: HttpService ) { }
    
    public getMessages(): Observable<any> {
        return this.httpService.get(BASE_URL + this.URL_PREFIX + "/hash/appversion/messages");
    }
}
