import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';
import {ResetPasswordRequest} from './reset-password-request';
import {ChangePasswordRequest} from './change-password-request';
import {map} from 'rxjs/operators';
import { HttpHeaders, HttpRequest, HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class CredentialsService {
     private readonly URL_PREFIX = '/credentials';

     private _currentUsername: string;

    constructor( private httpService: HttpService, private httpClient: HttpClient ) { }

    public isEmailRequired(): Observable<boolean> {
        return this.httpService.get(BASE_URL + this.URL_PREFIX + '/email/required')
            .pipe(
                map((data: any) => {
                    if (data.result) {
                        return data.payload;
                    }
                })
            );
    }

    public resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<any> {
        return this.httpService.post(BASE_URL + this.URL_PREFIX + '/password/reset', resetPasswordRequest);
    }

    public changePassword(changePasswordRequest: ChangePasswordRequest): Observable<any> {
        let token = window.sessionStorage.getItem("authToken");

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token });
        let options = { headers: headers };



        return this.httpClient.post(BASE_URL + this.URL_PREFIX + '/password/change', changePasswordRequest, options);
    }

    addToken(req: HttpRequest<any>, token: any): HttpRequest<any> {
        if(token){
            return req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }else{
            return req;
        }
    }


  get currentUsername(): string {
    return this._currentUsername;
  }

  set currentUsername(value: string) {
    this._currentUsername = value;
  }
}
