import { NgModule } from '@angular/core';
import {JrzHttpModule} from "jrz-http";

@NgModule({
  declarations: [],
  imports: [JrzHttpModule],
  exports: [],
  providers: []
})
export class JrzAuthApiModule { }
