import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {concatMap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {i18nService} from 'jrz-common';
import {ErrorMessage, HttpError} from 'jrz-http';
import {NewPasswordService, NewPasswordRequest} from 'jrz-auth-api';
import {appRoutesNames} from '../../app/app.routes.name';

@Component({
    selector: 'auth-ui-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss']
})

export class NewPasswordComponent implements OnInit {
    token: String;
    isTokenValid: boolean = false;
    newPasswordForm: FormGroup;
    initialLoading = false;
    submitted = false;
    errors: ErrorMessage[] = [];
    processing: boolean = false;
    success: boolean = false;
    public readonly backToLoginLink = '../' + appRoutesNames.LOGIN;

    constructor(
        private formBuilder: FormBuilder,
        private newPasswordService: NewPasswordService,
        public i18nService: i18nService,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        // Initialize form
        this.newPasswordForm = this.formBuilder.group({
            passwordNew: ['', Validators.required],
            passwordNewRepeat: ['', Validators.required]
        });

        // Validate the token
        this.route.queryParams
            .pipe(
                concatMap(params => {
                    this.token = params.token;
                    return this.newPasswordService.validateRequest(params.token);
                })
            )
            .subscribe(
                (res:any) => {
                    this.isTokenValid = res.result;
                },
                () => {
                    this.isTokenValid = false;
                }
            );
    }

    get form() {
        return this.newPasswordForm.controls;
    }

    onSubmit() {
        this.errors = [];

        // Validation
        if (this.newPasswordForm.invalid) {
            return;
        }

        if (this.newPasswordForm.value.passwordNew !== this.newPasswordForm.value.passwordNewRepeat) {
            this.errors = [{messageCode: 'changepassword.validation.new.passwords.dont.match', parameters: []}];
            return;
        }

        if (!this.newPasswordForm.value.passwordNew.match("^.*(?=.{8,})(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*$")) {
            this.errors = [{messageCode: 'changepassword.validation.new.password.not_strong', parameters: []}];
            return;
        }

        // Form is valid. Call API to change the password
        this.submitted = true;
        this.processing = true;
        const newPasswordRequest: NewPasswordRequest = this.newPasswordForm.value;
        this.newPasswordService.changePassword(this.token, newPasswordRequest).subscribe(res => {
            if (res.result) {
                this.success = true;
                this.processing = false;
            } else {
                this.isTokenValid = res.payload
                this.errors = res.messages;
                this.success = false;
                this.processing = false;
            }
        }, err => {
            if (err instanceof HttpError) {
                this.errors = err.messages;
                this.success = false;
                this.processing = false;
            }
        })
    }
}
