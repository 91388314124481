import { Injectable } from '@angular/core';
import {HttpService} from 'jrz-http';
import {Observable} from 'rxjs';
import {BASE_URL} from '../base-url.const';


@Injectable({
    providedIn: 'root'
})
export class NewPasswordService {
    private readonly URL_PREFIX = '/newpassword';

    constructor( private httpService: HttpService ) { }

    public validateRequest(token: String): Observable<Object> {
        return this.httpService.get(BASE_URL + this.URL_PREFIX + '/token/' + token + '/validate');
    }

    public changePassword(token:String, newPasswordRequest: NewPasswordRequest): Observable<any> {
        return this.httpService.put(BASE_URL + this.URL_PREFIX + '/' + token + "/change", newPasswordRequest);
    }

}

export class NewPasswordRequest {
    constructor(
        public passwordNew: string,
        public passwordNewRepeat: string
    ) {
    }
}
