import {Component, OnInit} from '@angular/core';
import {i18nService} from '../i18n.service';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  
  constructor(public i18nService: i18nService) {}

  ngOnInit() {
  }

  private semaphore: number = 1;

  public start() {
    this.semaphore++;
  }

  public stop() {
    this.semaphore--;
  }

  public isLoading() {
    return this.semaphore > 0;
  }
}
